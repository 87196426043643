<template>
  <div class="preloader" :class="{'_absolute': mode || mode > 0}">
    <ProloaderSVG v-if="!mode || mode === 0" />
    <PreloaderSVGmiddle v-else />
  </div>
</template>

<script>
import ProloaderSVG from '@/components/ui/lottie-svg/PreloaderSVG.vue'
import PreloaderSVGmiddle from '@/components/ui/lottie-svg/PreloaderSVGmiddle.vue'

export default {
  name: 'Preloader',
  props: { mode: Number, },
  components: { ProloaderSVG, PreloaderSVGmiddle }
}
</script>

<style lang="scss" scoped>
.preloader {
  position: fixed;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #000;
}
._absolute { position: absolute; }
</style>
