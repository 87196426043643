import Vue  from 'vue'
import Vuex from 'vuex'

// Static data
import setting from './modules/setting'

// API
import auth from './modules/auth'
import users from './modules/users'
import tasks from './modules/tasks'
import timer from './modules/timer'
import queues from './modules/queues'
import boards from './modules/boards'
import personal from './modules/personal'
import worktime from './modules/worktime'
import projects from './modules/projects'
import calendar from './modules/calendar'
import checklist from './modules/checklist'
import departments from './modules/departments'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    setting,
    auth,
    users,
    tasks,
    timer,
    queues,
    boards,
    personal,
    worktime,
    projects,
    calendar,
    checklist,
    departments,
  }
})