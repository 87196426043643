<template>
  <div>Aвторизация...</div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
export default {
  components: {},
  name: "YandexAuth",
  data() {
    return {
      access_token: "",
    };
  },
  props: {},
  computed: {
      ...mapGetters(['jwt']),
  },
  methods: {
    ...mapActions(['jwtCreateSocial']),
    getYandexToken() {
      this.access_token = /access_token=([^&]+)/.exec(document.location.hash)[1];
    },
  },
  mounted() {
    this.getYandexToken();
    this.jwtCreateSocial(this.access_token);
  },
};
</script>



<style scoped>
</style>
