export default {
  computed: {
    tasks: function() {
      let tasks = [];
      if ( this.getTasks.length ) {
        this.getTasks.forEach(t => {
          const leng = t.ispolniteli ? t.ispolniteli.length : 0;
          if ( leng ) {
            for ( let i = 0; i < leng; i++ ) {
              const obj = {
                id: t.id,
                user_id: Number(t.ispolniteli[i].id),
                sponsor: t.key,
                title: t.summary,
                link: `${process.env.VUE_APP_YANDEX_URL}${t.key}`,
                queue: t.queue.key,
                color: this.getColor(t),
                status: t.status.key,
                priority: Number(t.priority.id),
                list_components_id: this.getArrayComponentsId(t),
                times: {
                  starting_time: this.$moment(t.start)._d,
                  ending_time: this.$moment(t.deadline)._d
                },
                timestemp: this.getTimeStemp(t),
                author: Number(t.createdBy.id),
                _author: t.createdBy.display,
                description: t.description,
                checklist: t.checklistItems,
              };
              let _start = obj.times.starting_time,
                  _end   = obj.times.ending_time;
    
              if ( _start && _end && ( this.$moment(_start) < this.$moment(_end) || obj.timestemp ) ) {
                tasks.push(obj)
              }
            }
          }
        })
      };
      return tasks;
    },
    sortedTasks: function() {
      let sortedTasks = this.tasks;
      sortedTasks.sort((a, b) => {
        let taskA = this.$moment(a.times.starting_time);
        let taskB = this.$moment(b.times.starting_time);
        
        if ( taskA <= taskB ) return -1;
        if ( taskB < taskA ) return 1;
        return 0;
      });
      return sortedTasks;
    },
  },
  methods: {
    getColor(task) {
      const queue = this.getQueues.find(q => q.key === task.queue.key);
      const description = queue ? queue.description : '';
      const regexp = /#[a-f0-9]{6}\b/gi;

      let color = '#b7b7b7';
      if ( description && description.match(regexp) ) {
        color =  description.match(regexp)[0];
      }
      return color;
    },
    getTimeStemp(task) {
      const days_min = this.$moment.duration(task.originalEstimation)._days * 8 * 60;
      const mil_min = this.$moment.duration(task.originalEstimation)._milliseconds / 1000 / 60;
      return days_min +  mil_min;
    },
    getArrayComponentsId(task) {
      let arr = [];
      if ( task.components && task.components.length ) {
        task.components.forEach(c => {
          arr.push(Number(c.id))
        })
      }
      return arr;
    }
  },
}
