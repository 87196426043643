import '@/assets/scss/styles.scss'

import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'

import store from './store'
import router from './router'
import axios from 'axios'
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

import { Input, Select, Option, Button, Checkbox, Message, Table, TableColumn, TimePicker, Switch } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import elementLocale from 'element-ui/lib/locale/lang/ru-RU'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { 
  faPlay, 
  faStop,
  faInfo,
  faCheck, 
  faClock,
  faPause, 
  faPaperPlane,
  faCalendarAlt,
  faTimesCircle, 
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'

import * as d3 from 'd3'
import moment from 'moment'
import 'moment/locale/ru'

import marked from 'marked'

Vue.prototype.$d3 = d3;
Vue.prototype.$moment = moment;
Vue.prototype.$md = marked;
Vue.prototype.$message = Message;

axios.defaults.baseURL = process.env.VUE_APP_API;
axios.interceptors.request.use(function (request) {
  // установка headers для каждого запроса
  request.headers.authorization = store.getters.Authorization;
  return request;
}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
    if(error.response.status === 401) {
      // если получили 401 ошибку то перекидывает на авторизацию
      store.dispatch('jwtRemove');
      let path = location.pathname.substr(1);
      if (path !== 'login/'){
        router.push({path: `/login/?next=${path}`});
      }
    }
  return Promise.reject(error);
});

Vue.prototype.$http = axios;
Vuex.Store.prototype.$http = axios;


library.add(
  faPlay, 
  faStop, 
  faInfo, 
  faCheck,
  faPause, 
  faClock, 
  faPaperPlane, 
  faCalendarAlt, 
  faTimesCircle, 
  faExternalLinkAlt,
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(LottieAnimation);
Vue.use(Input,  { locale: elementLocale })
Vue.use(Select, { locale: elementLocale })
Vue.use(Option, { locale: elementLocale })
Vue.use(Checkbox, { locale: elementLocale })
Vue.use(Button, { locale: elementLocale })
Vue.use(Table, { locale: elementLocale })
Vue.use(TableColumn, { locale: elementLocale })
Vue.use(TimePicker, { locale: elementLocale })
Vue.use(Switch, { locale: elementLocale })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
