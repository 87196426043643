<template>
  <nav v-if="Object.keys(getUser).length">
    <ul class="navigation" >
      <li v-for="link in listItems" :key="link.id"
        class="navigation__item _mx-2">
        <router-link :to="{name: link.name, params: link.params }">
          <el-button class="navigation__link" round>
            {{ link.txt }}
          </el-button>
        </router-link>
      </li>
      <li class="navigation__item _mx-2">
          <router-link :to="{ name: 'MeTasks', params: { login: getUser.login, id: getUser.uid } }">
            <el-button class="navigation__link" round>
              {{ getUser.display }}
            </el-button>
          </router-link>
      </li>
      <li class="navigation__item _mx-2">
          <router-link :to="{ name: 'Projects' }">
            <el-button class="navigation__link" round>
              Projects
            </el-button>
          </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Navigation',
  data: () => ({
    filter: {
      departments: [2, 18, 19, 20]
    },
  }),
  computed: {
    ...mapGetters(['getDepartments', 'getUser']),
    listItems: function() {
      let listItems = [{ id: 1, txt: 'All', name: 'All', params: { name: 'all' } }];
      if ( this.getDepartments.length ) {
        this.getDepartments.forEach(d => {
          let uri = d.name.toLowerCase();
          const obj = {
            id: d.id,
            txt: d.name,
            name: 'Dashboard',
            params: {
              name: uri.replace(/ /g, '-'),
              id: d.id
            }
          };
          listItems.push(obj)
        })
      };
      return listItems;
    }
  },
  methods: {
    ...mapActions(['fetchDepartments', 'fetchUser']),
  },
  async created() {
    await this.fetchDepartments(this.filter);
    if ( !this.getUser.length ) {
      await this.fetchUser();
    }
  }
}
</script>

<style lang="scss" scoped>
._mx-2 {
  margin-left: 8px;
  margin-right: 8px;
}
.navigation {
  display: flex;
  &__item a {
    text-decoration: none;
  }
  & .navigation__link {
    border-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    height: 30px;
    color: #fff;
    background-color: #000;
    border-width: 1px;
    font-weight: 400;
    transition: all .3s ease;

    &:hover {
      background-color: #fff;
      color: #000;
      border-color: #fff;
    }
  }
  & .router-link-exact-active {
    pointer-events: none;
    & .navigation__link {
      background-color: #fff;
      color: #606266;
      border-color: #fff;
      pointer-events: none;
    }
  }
}
</style>
