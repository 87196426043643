export default ({
  state: {
    calendar: [],
  },
  getters: {
    getCalendar: state => state.calendar
  },
  mutations: {
    setCalendar(state, calendar) {
      state.calendar = calendar
    }
  },
  actions: {
    async fetchCalendar(ctx, year) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }

      fd.append('url', `http://xmlcalendar.ru/data/ru/${year}/calendar.json`);
      fd.append('method', 'GET');
      fd.append('headers', JSON.stringify(headers));
      
      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {
          let res = response.data.months;
          res.forEach(obj => {
            obj.days = obj.days.split(',');
          })
          ctx.commit('setCalendar', res);
      })
    }
  },
});