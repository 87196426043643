<template>
  <div class="lottie-wrap">
    <lottie-animation
      path="lottie/v1/data.json"
      :loop="true"
      :autoPlay="true"
      :speed="1"
    />
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  name: 'PreloaderSVG',
  components: { LottieAnimation },
}
</script>

<style lang="scss">
.lottie-wrap {
  & svg {  max-width: 900px; }
}
</style>
