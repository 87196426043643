export default ({
  state: { 
    tasks: [],
    transitions: [],
  },
  getters: { 
    getTasks: state => state.tasks,
    getTransitions: state => state.transitions,
  },
  mutations: { 
    setTasks(state, tasks) {
      state.tasks = tasks
    },
    setTransitions(state, transitions) {
      state.transitions = transitions
    },
  },
  actions: {
    async fetchTasks(ctx, filter = ctx.getters.IssueFilter) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }

      fd.append('url', `${process.env.VUE_APP_YANDEX_TRACKER}/v2/issues/_search?scrollType=sorted&perScroll=300`); // scrollType=sorted&perScroll=1000
      fd.append('method', 'POST');
      fd.append('headers', JSON.stringify(headers));
      fd.append('data', JSON.stringify(filter));
      
      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {
          const tasks = response.data;
          ctx.commit('setTasks', tasks)
      })
    },

    async fetchTransitions(ctx, task_id='614498f5807fec38d87836ba') {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }

      fd.append('url', `${process.env.VUE_APP_YANDEX_TRACKER}/v2/issues/${task_id}/transitions?`);
      fd.append('method', 'GET');
      fd.append('headers', JSON.stringify(headers));
      
      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {
          ctx.commit('setTransitions', response.data)
      })
    },

    async changeStatusTask(ctx, { task_id, status_id }) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }

      fd.append('url', `${process.env.VUE_APP_YANDEX_TRACKER}/v2/issues/${task_id}/transitions/${status_id}/_execute`);
      fd.append('method', 'POST');
      fd.append('headers', JSON.stringify(headers));
      
      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {
          if ( response.status === 200 ) {
            setTimeout(() => {
              ctx.dispatch('fetchTasks');
            }, 1000);
          }
      })
    },

    async addComment(ctx, { issue_id, text }) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }
      const payload = {
        "text": text
      }

      fd.append('url', `${process.env.VUE_APP_YANDEX_TRACKER}/v2/issues/${issue_id}/comments?`);
      fd.append('method', 'POST');
      fd.append('headers', JSON.stringify(headers));
      fd.append('data', JSON.stringify(payload));

      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {
          if ( response.status === 200 ) {
            return response;
          }
      })
    },

    async updateDateIssue(ctx, { issue_id, data }) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }

      fd.append('url', `${process.env.VUE_APP_YANDEX_TRACKER}/v2/issues/${issue_id}`);
      fd.append('method', 'PATCH');
      fd.append('headers', JSON.stringify(headers));
      fd.append('data', JSON.stringify(data));

      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {
          if ( response.status === 200 ) {
            setTimeout(() => {
              ctx.dispatch('fetchTasks');
            }, 1000);
          }
      })
    }
  },
})
