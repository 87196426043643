<template>
  <div class="container-tech">
  <div>
    <div class="skill__row">
      <div class="button button__skill"> Навыки </div>
      <div class="button skill__weight"> Вес </div>
      <div class="button skill__level"> Тип навыка </div>
    </div>
    <transition name='modaltransition'>

      <ModalBox  v-if="isOpen" :category_id="category_id" :skill="skill" @closeModal="closeModalBox()"/>
    </transition>
  </div>
    <div v-for="skill in categorySkills" :key="skill.id">
      <SkillItem :category_id="category_id" :skill="skill"/>
    </div>
  </div>
</template>

<script>
import SkillItem from '@/components/personal/SkillItem.vue'

export default {
  name: 'Skills',
  components: {
    SkillItem
  },
  props: {
    categorySkills: {
      type: Array,
      required: true
    },
    category_id: {
      type: Number,
    }
  },
}
</script>

<style scoped lang="scss">
  .container-tech {
      display: flex;
      flex-direction: column;
    }
  .skill__row {
    display: flex;
    
  }
  .button.button__skill {
    width: 300px;
    justify-content: flex-start;
    color: #000;
    cursor: auto;
  }

  .button.skill__level {
    width: 150px;
    color: black;
    // background-color: grey;
    cursor: auto;

  }
  .button.skill__weight {
    color: black;
    width: 50px;
    // background-color: grey;
    cursor: auto;
  }

</style>