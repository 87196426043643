import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import axios from 'axios'

import All from '@/views/All'
import MeTasks from '@/views/_id/MeTasks'
import Dashboard from '@/views/_id/Dashboard'

import Projects from '@/views/Projects'

import Personal from '@/views/personal/Personal'
import PersonalStats from '@/views/personal/PersonalStats'
import Users from '@/views/personal/Users'

import LoginPage from '@/views/LoginPage'
import YandexAuth from '@/views/YandexAuth'

import Err404 from '@/views/404'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'All',
    component: All,
    meta: { layout: 'default', requiresAuth: true },
  },
  {
    path: '/assignee/:login',
    name: 'MeTasks',
    component: MeTasks,
    meta: { layout: 'default', isMe: true },
  },
  {
    path: '/dashboard/:id/:name',
    name: 'Dashboard',
    component: Dashboard,
    meta: { layout: 'default', isDepartments: true },
  },
  {
    path: '/personal/',
    name: 'PersonalMy',
    component: Personal,
  },
  {
    path: '/users/',
    name: 'Users',
    component: Users,
  },
  {
    path: '/personal/user/:id',
    name: 'Personal',
    component: Personal,
  },
  {
    path: '/personal-stats/:login',
    name: 'PersonalStats',
    component: PersonalStats,
    meta: { layout: 'personal-layout', isMe: true },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: Projects,
    meta: { layout: 'default', requiresAuth: true },
  },
  {
    path: '/auth/login/yandex/',
    name: 'YandexAuth',
    component: YandexAuth,
    meta: { layout: 'empty' }
  },
  {
    path: '/login/',
    name: 'LoginPage',
    component: LoginPage,
    meta: { layout: 'empty' }
  },
  
  {
    path: '*',
    name: 'Err404',
    component: Err404,
    meta: { layout: 'default' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

async function getUser(to, next) {
  const fd = new FormData();
  let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': `OAuth ${token}`,
    'X-Org-Id': process.env.VUE_APP_ORG_ID,
  }
  fd.append('url', `${process.env.VUE_APP_YANDEX_TRACKER}/v2/myself`);
  fd.append('method', 'GET');
  fd.append('headers', JSON.stringify(headers));

  await axios.post('/auth/yandex_request/', fd)
    .then(response => {
      const login = response.data.login
      if ( to.params.login === login ) {
        return next()
      } else {
        return next('/404')
      }
  })
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!localStorage.getItem('jwt')) {
      return next('/login/');
    } else {
      return next();
    }
  } else if (to.matched.some((record) => record.meta.isMe)) {
    if (!localStorage.getItem('jwt')) {
      return next('/login/');
    } else {
      await getUser(to, next);
    } 
  } else if (to.matched.some((record) => record.meta.isDepartments)) {
    if (!localStorage.getItem('jwt')) {
      return next('/login/');
    } else {
      const departments = [2, 18, 19, 20];
      if ( departments.includes(Number(to.params.id)) ) {
        return next()
      } else {
        return next('/404')
      }
    } 
  } else {
    next();
  }
});
