<template>
  <div class="_relative">
    <section class="top _mb-4">
      <Navigation />

      <!-- <div class="scale">
        <el-select v-model="zoom" popper-class="app-select" placeholder="Масштаб">
          <el-option
            v-for="item in scaleOptions"
            :key="item.zoom"
            :label="item.label"
            :value="item.zoom">
          </el-option>
        </el-select>
      </div> -->
    </section>

    <ChartProjects  v-if="sortedProjects && sortedProjects.length"
      :projects="sortedProjects" />

    <div v-else>
      <Preloader />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Preloader from '@/components/ui/Preloader'
import Navigation from '@/components/ui/Navigation'
import ChartProjects from '@/components/ChartProjects'

export default {
  name: 'Projects',
  components: { Preloader, Navigation, ChartProjects },
  data: () => ({}),
  computed: {
    ...mapGetters(['getProjects']),
    sortedProjects: function() {
      if ( this.getProjects && this.getProjects.length ) {
        let sortedProjects = this.getProjects;
        sortedProjects = sortedProjects.filter(obj => obj.status === 'draft' || obj.status === 'in_progress');

        sortedProjects.sort((a, b) => {
          let projectA = this.$moment(a.startDate);
          let projectB = this.$moment(b.startDate);
          
          if ( projectA <= projectB ) return 1;
          if ( projectB <  projectA ) return -1;
          return 0;
        });
        return sortedProjects;
      }
    },
  },
  methods: {
    ...mapActions(['fetchProjects']),
  },
  async created() {
    await this.fetchProjects();
  },
}
</script>

<style lang="scss" scoped>
._relative { position: relative; }
._mb-4 { margin-bottom: 16px; }

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}
</style>