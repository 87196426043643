export default ({
  state: {
    Stats: [],
    Timing: [],
    Status: {},
  },
  getters: {
    getStats: state => state.Stats,
    getTiming: state => state.Timing,
    getStatus: state => state.Status,
  },
  mutations: {
    setStats(state, Stats) {
      state.Stats = Stats;
    },
    setTiming(state, Timing) {
      state.Timing = Timing;
    },
    setStatus(state, Status) {
      state.Status = Status;
    }
  },
  actions: {
    async fetchStats(ctx) {
      this.$http.get(`/api/timer/month/`)
        .then(response => {
          if (response.status) {
            ctx.commit('setStats', response.data);
          }
      })
    },

    async fetchTiming(ctx, {val, time}) {
      let fd = new FormData();
      fd.append('Content-Type', 'application/example');
      fd.append(val, time);

      this.$http.patch(`/api/timer/check/`, fd)
        .then(response => {
          if (response.status) {
            ctx.commit('setStatus', response.data);
          }
      })
    },

    async fetchStatus(ctx) {
      this.$http.get(`/api/timer/today/`)
        .then(response => {
          if (response.status) {
            ctx.commit('setStatus', response.data);
          }
      })
    }
  },
})
