<template>
  <div>
    <div v-if="jwt" class="auth__form">
      <h1>Вы авторизованы</h1>
      <form v-on:submit.prevent="jwtRemove">
        <button ref="submit">Выйти</button>
      </form>
          <router-link to="/"><button class="link">перейти в DOBRO Tracker</button></router-link>
    </div>

    <div v-else class="auth__form">
      <h1>Авторизация</h1>
      <form v-on:submit.prevent="jwtCreate(authForm)">
        <input v-model="authForm.email" type="text" placeholder="Почта" />
        <input v-model="authForm.password" type="password" placeholder="Пароль"/>
        <button ref="submit">Войти</button>
      </form>
      <a :href="nextUrl"> <button class="link">Авторизация через почту @doboragency</button></a>
        <div class="error">{{ errorMsg }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
  },
  name: "LoginPage",
  data() {
    return {
      authForm: {
        email: "",
        password: "",
      },
    };
  },
  props: {},
  computed: {
    ...mapGetters(["jwt", "socialLink", "errorMsg"]),
    nextUrl() {
      if (!this.socialLink) {
        return this.socialLink
      }
      let url_social = new URL(this.socialLink);
      let redirect_uri = url_social.searchParams.get("redirect_uri");
      let url = new URL(window.location.href);
      let next = url.searchParams.get("next");
      if (next) {
        return url_social.href.replace(redirect_uri, `${redirect_uri}?next=${next}`);
      }
      return this.socialLink;
    }
  },
  methods: {
    ...mapActions(["jwtCreate", "jwtRemove", "getSocialLink"]),
  },
  mounted() {
    this.getSocialLink();
  },
};
</script>

<style>

:root {
    --army: #1e4e42;
    --reverse-hover: #fff;
    --reverse: #c1c1c1;
    --body-text: #acacac;
    --body-bg: #2e2e2e;
    --main-window: #1a1a1a;
    --secondary: #353535;
    --secondary-hover: #1a1a1a;
    --selected: #7b9aff;
    --light: #c1c1c1;
    --light-hover: #fff;
    --warning: #dca314;
    --warning-hover: #ffb805;
    --error: red;
    --error-hover: #fc3939;
    --success: green;
    --success-hover: #00a400;
    --primary: #2a389b;
    --primary-hover: #3648cd;
    --grey: #5e5e5e;
    --grey-hover: #555;
    --dark: #1a1a1a;
    --dark-hover: #000;
}

</style>

<style scoped>




.auth__form,
form {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 350px;
}

input,
input:-webkit-autofill {
  height: 40px;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid var(--dark);
  outline: 0px;
  background-color: var(--light);
  -webkit-box-shadow: 0 0 0 30px var(--light) inset !important;

}

input:active, 
input:focus, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--light-hover) inset !important;

}
input::placeholder, textarea::placeholder {
  text-align: center;
}



button {
  height: 40px;
  background-color: var(--primary);
  color: var(--light-hover);
  border: 0;
  transition: 0.3s ease-out all;
  cursor: pointer;
}

button:hover {
  background-color: var(--primary-hover);
  color: var(--light-hover);
}

h1 {
  align-self: center;
  margin-bottom: 20px;
}

.error {
  color: var(--error);
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

button.link{
background-color: var(--success);
width: 100%;
margin-top: 10px;
}

button.link:hover{
background-color: var(--success-hover);
}

</style>
