<template>

  <div>
    <div class="skill__row">

    <button 
      @click="showModal()" class="button button__skill" 
      :style="{ background: `linear-gradient(to right, #fce463 ${fillFull}%, #FFFFFFFF ${fillFull}%, #FFFFFFFF 100%, #fff 100%)` }"
    >
      {{skill.skill.name}}
    </button>
    <div class="button skill__weight"> {{skill.weight}} </div>
    <!-- <div class="button skill__level"> {{skill.level}} </div> -->
    <div class="button skill__level"> {{skill.skill.skill_type}} </div>
    </div>
    <transition name='modaltransition'>

      <ModalBox  v-if="isOpen" :category_id="category_id" :skill="skill" @closeModal="closeModalBox()"/>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModalBox from '@/components/personal/ModalBox.vue'

export default {
  name: 'SkillItem',
  components: {
    ModalBox
  },
  props: {
    skill: {
      type: Object,
      required: true
    },
    category_id: {
      type: Number,
    }
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    fillEmpty() {
      return (5 - this.skill.level) / 5 * 100;
    },
    fillFull() {
      return this.skill.level / 5 * 100;
    }
  },
  methods: {
    ...mapActions(['clearSkillInfo']),
    showModal() {
      this.isOpen = true;
    },
    closeModalBox() {
      this.isOpen = false;
      this.clearSkillInfo();
    }
  }
}
</script>

<style scoped>

.skill__row {
  display: flex;
  
}
.button.button__skill {
  width: 300px;
  justify-content: flex-start;
}

.button.button__skill:hover {
  opacity: 0.7;
}

  .button.skill__level {
    width: 150px;
    background-color: grey;
    cursor: auto;

  }
  .button.skill__weight {
    width: 50px;
    background-color: grey;
    cursor: auto;
  }
</style>