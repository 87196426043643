<template>
  <section class="home">
    <Chart  v-if="sortedTasks && sortedTasks.length"
      :tasks="sortedTasks" 
      :users="allUsers" />

    <div v-else>
      <Preloader />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import sortedTasks from '@/mixins/sortedTasks'
import applyAvatar from '@/mixins/applyAvatar'

import Chart from '@/components/Chart'
import Preloader from '@/components/ui/Preloader'

export default {
  name: 'All',
  components: { Chart, Preloader },
  mixins: [sortedTasks, applyAvatar],
  data: () => ({
    filter: {
      departments: [2, 18, 19, 20]
    },
  }),
  computed: {
    ...mapGetters(['getUsers', 'getAvatars', 'getTasks', 'getQueues']),
    allUsers: function() {
      let users = [];
      if ( this.getUsers.length ) {
        this.getUsers.forEach(u => {
          let obj = {
            id: u.id,
            user: `${u.name.first} ${u.name.last}`,
            position: u.position,
            img: this.getUserAvatar(u.email),
          }
          users.push(obj);
        })
      };
      return users;
    },
  },
  methods: {
    ...mapActions(['fetchUsers', 'fetchTasks', 'fetchQueues']),
  },
  async created() {
    await this.fetchQueues();
    await this.fetchUsers(this.filter);
    await this.fetchTasks();
  }
}
</script>
