export default ({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async checkItem(ctx, { data, issue_id, item_id }) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }

      fd.append('url', `${process.env.VUE_APP_YANDEX_TRACKER}/v2/issues/${issue_id}/checklistItems/${item_id}/`);
      fd.append('method', 'PATCH');
      fd.append('headers', JSON.stringify(headers));
      fd.append('data', JSON.stringify(data));
      
      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {
          console.log(response);
      })
    }
  },
});