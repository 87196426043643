<template>
  <section class="wrap">
    <div>
      <p class="error-code">404</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Err404',
}
</script>

<style lang="scss" scoped>
.wrap {
  position: relative;
  display: grid;
  place-items: center;
  margin-top: 15vh;
  width: 100%;
}

.error-code {
  flex: 1 1 auto;
  font-size: 225px;
  font-weight: 600;
  animation: fadeWhite 4s forwards;
  animation-delay: 2s;
  position: relative;
  &:after {
    @include glitchText(3px, '404', #fff);
  }
  &:before {
    @include glitchText(-3px, '404', #fff);
  }
}

@keyframes fadeWhite {
  100% { color: #fff; }
}

@keyframes glitch {
  $steps: 20;
  @for $i from 0 through $steps{
    #{percentage($i * (1 / $steps))}{
      clip: rect(random(800)+px, 9999px, random(800)+px, 0);
    }
  }
}
</style>
