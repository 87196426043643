<template>
  <div class="content">
    
    <div class="skill__dashboard">
      <div class="skill__dashboard_cat">
        <div v-if="thisPerson">
        {{thisPerson.first_name}} {{thisPerson.last_name}} ({{thisPerson.email}})
        </div>
        <div 
          v-for="(category, index) in SkillCategoryList" :key="index"
          class="main" :class="isExpand ? 'expand' : 'unexpand'"
        >
          <button class="button title-spec" @click="Expand()">
            <span class="cross" :class="isExpand ? 'open' : 'close'"></span>
            <div class="icon-expand">
              {{category.name}} <span> (total: {{category.total}})</span>
            </div>
          </button>
          
          <Grade v-if="showBlock==='grade'" :grades="category.grades" :total="category.total"/>
          <Skills v-if="showBlock==='skills'" :category_id="category.id" :categorySkills="category.category_skills"/>
          <Matrix v-if="showBlock==='matrix'" :matrix="matrix"/>
        </div>
          
      </div>
    </div>
     
  </div>
</template>

<script>
import Grade from '@/components/personal/Grade.vue'
import Skills from '@/components/personal/Skills.vue'
import Matrix from '@/components/personal/Matrix.vue'
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SkillDashboard",
  components: {
    Grade,
    Skills,
    Matrix,
  },
  props: {
    SkillCategoryList: {
      type: Array,
      required: true
    },
    matrix: {
      type: Array,
    },
    showBlock: {
      type: String,
    }
  },
  data: () => ({
    isExpand: true,
  }),
  computed: {
    ...mapGetters(["getPersons", 'getPerson']),
    thisPerson() {
      let users = this.getPersons;
      let user_id = this.$route.params.id;
      if (users && users.length) {
        for (let user of users) {
          if (user.id==user_id) {
            return user;
          }
          }
        }
      return
    },
  },
  methods: {
    ...mapActions(["fetchPersons", 'fetchPerson']),
    Expand() {
      this.isExpand = !this.isExpand;
    }
  },
  created() {
    if (!(this.getPersons && this.getPersons.length)) {
      this.fetchPersons();
    }

  },
}
</script>

<style lang="scss">
  $tablet: 1024px;
  $mobile: 767px;
  .button-togle {
    font-size: 14px;
    font-weight: bold;
    color:black;
    background-color: rgb(255, 255, 255);
    border: 0;
    margin: 10px;
    height: 40px;
    min-width: 100px;
    border-radius: 10px;
    cursor: pointer;
    &.active {
      background-color: rgb(252, 228, 99);
    }
  }

 .skill__dashboard {
   display: flex;
   justify-content: flex-start;
   flex-wrap: wrap;
   width: 100%;
 }

 .skill__dashboard_cat{
   width: max-content;
 }
 
  .content {
    width: 100%;
    margin: 0 auto;
    padding: 0 35px 0 20px;
    @media screen and (max-width: $mobile) {
      padding: 0;
    }
  }
  .main {
    display: grid;
    grid-template-columns: 222px 1fr;
    grid-gap: 20px 35px;
    padding: 50px 0;
    overflow: hidden;
    position: relative;
    max-height: 180px;
    transition: max-height .3s cubic-bezier(0, 1, 0, 1);
    &.expand {
      max-height: 3000px;
      transition: max-height .3s ease-in-out;
      .row-skills::after {
        display: none;
      }
    }
    & .button {
      font-size: 14px;
      font-weight: bold;
      padding: 9px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 15px 15px 0;
      border-radius: 5px;
      line-height: 12px;
      cursor: pointer;
      border: none;
      box-shadow: none;
      background-color: #e3eaf0;
      transition: all .2s ease;
      &:focus {
        outline: 0;
      }
      &.checked {
        background-color: #fce463;
        &:hover {
          background-color: #a89b4f;
        }
      }
      &.un-checked:hover {
        background-color: #7f8a93;
      }
      &.title-spec {
        width: 144px;
        height: 47px;
        font-size: 18px;
        line-height: 22px;
        background-color: transparent;
        color: currentColor;
        padding: 0;
        justify-content: flex-start;
        align-items: flex-start;
        @media screen and (max-width: $mobile) {
          height: 27px;

        }
      }
    }
    & span.cross {
      position: relative;
      min-width: 20px;
      height: 20px;
      margin-right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 50%;
      &::after, &::before {
        content: '';
        display: block;
        width: 8px;
        height: 2px;
        border-radius: 2px;
        background-color: #1d2734;
        position: absolute;
        transition: 0.2s ease;
      }
      &::after {
        transform: rotate(90deg);
      }
      &.open::after {
        transform: rotate(0);
      }
    }
    & .icon-expand {
      text-align: left;
    }
    @media screen and (max-width: $tablet) {
      grid-template-columns: 145px 1fr;
    }
    @media screen and (max-width: $mobile) {
      grid-template-columns: 1fr;
      grid-gap: 0;
      padding: 30px 0;
    }
  }
  
</style>