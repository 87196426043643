<template>
  <section class="card" @click.stop="">
    <div class="card__header">
      <div class="card__priority priority">
        <div v-for="i in 5" :key="i" 
          class="priority__item"
          :class="[
            {'priority__item_active': i <= data.priority},
            {'priority__item_active_light': data.priority <= 2 && i <= data.priority},
            {'priority__item_active_hard': data.priority > 3 && i <= data.priority}
          ]">
        </div>
      </div>
      <button class="card__btn" @click="$emit('close')">
        <font-awesome-icon icon="times-circle" />
      </button>
    </div>

    <div class="card__body">
      <div class="_fx _space-between">
        <h3 class="card__title">
          {{ data.title }}
        </h3>
        <a :href="`${data.link}`" target="_blank" class="card__link">
          <font-awesome-icon icon="external-link-alt" />
        </a>
      </div>
      
      <h4 class="card__subtitle">
        Автор: {{ data._author }}
      </h4>

      <div class="card__description" v-html="htmlDescription"></div>
    </div>

    <div class="card__footer">
      <div class="calendar">
        <font-awesome-icon class="calendar__icon" icon="calendar-alt" />
        <p class="calendar__time">
          {{ $moment(data.times.starting_time).format('DD.MM.YYYY') }} - 
          {{ $moment(data.times.ending_time).format('DD.MM.YYYY') }}
        </p>
      </div>

      <div class="clock" v-if="data.timestemp">
        <font-awesome-icon class="clock__icon" icon="clock" />
        <p class="clock__time">
          {{ data.timestemp / 60 }} ч.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'IssueCard', 
  props: {data: Object},
  computed: {
    htmlDescription: function() {
      let html = '';
      if ( this.data.description ) {
        let description = this.data.description.replace(/\(\(/g, '{[');
        description = description.replace(/\)\)/g, ']}');
        let substrDescList = description.match(/[^\{\}]+(?=])/g);
        
        if ( substrDescList && substrDescList.length ) {
          substrDescList.forEach(str => {
            let new_str = str.replace(/ /, '±');
            let new_str_arr = new_str.split('±');
            new_str_arr.reverse();
            let string = `[${new_str_arr[0]}](${new_str_arr[1]})`;
            description = description.replace(/{[^\{\}]+[?=}]/, string);
          })
        }
        html = this.$md(description);
        html = html.replaceAll('%5B', '');
      }
      return html
    }
  },
  mounted() {
    const listLink = document.querySelectorAll('.card__description a');
    if ( listLink.length ) {
      listLink.forEach(link => {
        link.setAttribute('target', '_blank')
      })
    };
  }
}
</script>

<style lang="scss" scoped>
.card {
  width: 650px;
  border-radius: 8px;
  background-color: #000;
  color: #fff;
  border: 1px solid #fff;
  overflow-x: hidden;
  overflow-y: hidden;
  &__description { overflow-x: hidden; }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid #fff;
    margin-bottom: 16px;
  }

  &__btn {
    background: none;
    padding: 0;
    border: none;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
      filter: opacity(.7);
    }
  }

  &__body { padding: 16px 24px; }
  &__subtitle { color: #bababa; }
  &__link {
    height: fit-content;
    margin-left: 25px;
    color: #4ad4ff;
    transition: all .3s ease;
    &:hover { color: #02a1d1; }
  }

  &__description {
    margin-top: 24px;
    padding-right: 10px;
    color: #dedede;
    max-height: 400px;
    overflow-y: auto;
    scrollbar-color: #4d4d4d #fff; 
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 15px;
      background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 15px;
      background-color: #4d4d4d;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 15px;
      background-color: #fff;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    background: #dedede;
    color: #000;
  }
}

.priority {
  display: flex;
  align-items: flex-end;
  border-radius: 3px;
  overflow: hidden;
  &__item {
    height: 25px;
    width: 8px;
    min-width: 4px;
    background-color: #fff;
    border: .5px solid #fff;
    &_active { 
      background-color: #ffc36a;
      border: .5px solid #fcba57;
      &_light { background-color: #0a9d45; border: .5px solid #0ec256; }
      &_hard  { background-color: #eb5757; border: .5px solid #e23a3a; } 
    }
  }
}

.calendar {
  display: flex;
  align-items: center;
  &__icon { font-size: 18px; }
  &__time {
    margin-left: 8px;
    line-height: 0;
    padding: 0;
  }
}
.clock {
  display: flex;
  align-items: center;
  margin-left: 16px;
  &__icon { font-size: 18px; }
  &__time {
    margin-left: 8px;
    line-height: 0;
    padding: 0;
  }
}

._fx { display: flex; }
._space-between { justify-content: space-between; }
</style>

<style lang="scss">
.card__body {
  font-size: 18px;
  & a:not([class]) {
    position: relative;
    display: inline-flex;
    color: #4ad4ff;
    transition: all .3s ease;
    text-decoration: none;
    &::before {
      content: '';
      position: absolute;
      height: 1px;
      background-color: #4ad4ff;
      top: 100%;
      left: 0;
      width: 1ch;
      transition: all .3s ease;
    }

    &:hover { 
      color: #02a1d1; 
      text-decoration: none;
      &::before {
        background-color: #02a1d1;
        width: 100%;
      }
    }
  }
  & p { margin-bottom: 16px; }
  & pre {
    margin: 10px;
    white-space: inherit;
    font-family: 'Pragmatica';
    & code { white-space: inherit; }
  }
}
</style>
