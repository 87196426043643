export default ({
  state: { queues: [] },
  getters: { getQueues: state => state.queues },
  mutations: { 
    setQueues(state, queues) {
      state.queues = queues
    },
  },
  actions: {
    async fetchQueues(ctx) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }
      fd.append('url', `${process.env.VUE_APP_YANDEX_TRACKER}/v2/queues`);
      fd.append('method', 'GET');
      fd.append('headers', JSON.stringify(headers));
      
      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {
          ctx.commit('setQueues', response.data)
        })
    }
  },
})
