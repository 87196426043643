<template>
  <section v-if="stats && stats.length">
    <div class="content">
      <el-table :data="stats" stripe>
        <el-table-column prop="day" label="День" width="175">
        </el-table-column>
        <el-table-column prop="entrance" label="Пришел работать" width="215">
        </el-table-column>
        <el-table-column prop="dinner" label="Ушел на обед" width="215">
        </el-table-column>
        <el-table-column prop="after_dinner" label="Пришел с обеда" width="215">
        </el-table-column>
        <el-table-column prop="leave" label="Ушел домой" width="215">
        </el-table-column>
        <el-table-column prop="calcul_time" label="общее рабочее время" width="330">
        </el-table-column>
      </el-table>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PersonalStats',
  computed: {
    ...mapGetters(['getStats']),
    stats () {
      let stats = []
      if (this.getStats && this.getStats.length ) {
        this.getStats.forEach(stat => {
          for (let [key, val] of Object.entries(stat)) {
            if (val) {
              if (key === 'day') {
                let str = this.$moment(val).format("ddd Do MMM YY");
                stat[key] = this.cfl(str);
              } else if (key === 'calcul_time') {
                stat[key] = this.$moment(val, "HH:mm:ss").format("LT");
              } else if (this.$moment(val, this.$moment.ISO_8601, true).isValid()) {
                stat[key] = this.$moment(val).format("HH:mm");
              }
            }
          };
          stats.push(stat)
        })
      };
      return stats;
    },
  },
  methods: {
    ...mapActions(['fetchStats']),
    cfl (string) { // capitalize First Letter
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  async created() {
    await this.fetchStats();
  }
}
</script>

<style lang="scss">
  .el-table {
    color: #fff !important;
    margin: 0 auto;
    &__{
      &body, &header, &footer {
        width: 100% !important;
      }
    }
    td.el-table__cell {
      background-color: #000 !important;
    }
    & .el-table__row--striped td.el-table__cell{
      background-color: #1d1d20 !important;
    }
    &--enable-row-hover tr:hover>td.el-table__cell {
      background-color: #3d3d3e !important;
    }
  }
  
</style>
