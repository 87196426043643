export default ({
  state: {
    projects: '',
  },
  getters: {
    getProjects: state => state.projects
  },
  mutations: {
    setProjects(state, projects) { state.projects = projects }
  },
  actions: {
    async fetchProjects(ctx) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }
      fd.append('url', `${process.env.VUE_APP_YANDEX_TRACKER}/v2/projects`);
      fd.append('method', 'GET');
      fd.append('headers', JSON.stringify(headers));
      
      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {
          const filteredProjects = response.data.filter(obj => !!obj.startDate && !!obj.endDate)
          ctx.commit('setProjects', filteredProjects)
      })
    },
    async checkAccess(ctx) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }
      fd.append('url', `${process.env.VUE_APP_YANDEX_TRACKER}/v2/myself`);
      fd.append('method', 'GET');
      fd.append('headers', JSON.stringify(headers));
      
      this.$http({
        method: 'post',
        url: process.env.VUE_APP_API,
        data: fd
      })
        .then(response => {
          return response;
      })
    }
  },
})
