<template>
  <section class="home" v-if="getUsers.length && allUsers.length">
    <Chart  v-if="sortedTasks && sortedTasks.length"
      :tasks="sortedTasks" 
      :users="allUsers" />

    <div v-else>
      <Preloader />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import sortedTasks from '@/mixins/sortedTasks'
import applyAvatar from '@/mixins/applyAvatar'

import Chart from '@/components/Chart'
import Preloader from '@/components/ui/Preloader'

export default {
  name: 'MeTasks',
  components: { Chart, Preloader },
  mixins: [sortedTasks, applyAvatar],
  data: () => ({
    filter: {
      departments: [2, 18, 19, 20]
    },
  }),
  computed: {
    ...mapGetters(['getUsers', 'getAvatars', 'getTasks', 'getUser', 'getQueues']),
    allUsers: function() {
      let users = [];
      if ( this.getUsers.length && this.getUsers.find(u => u.nickname === this.$route.params.login) ) {
        let obj = {
          id: this.getUser.uid,
          user: `${this.getUser.display}`,
          position: this.getUsers.find(u => u.nickname === this.$route.params.login).position,
          img: this.getUserAvatar(this.getUser.email),
        }
        users.push(obj);
      };
      return users;
    },
  },
  methods: {
    ...mapActions(['fetchUsers', 'fetchTasks', 'fetchQueues']),
  },
  async created() {
    await this.fetchQueues();
    await this.fetchUsers(this.filter);
    await this.fetchTasks();
  },
  // async mounted() {
  //   if ( this.$route.params.login !== this.getUser.login ) {
  //     this.$router.push({ name: 'Err404' })
  //   }
  // }
}
</script>
