import router from '@/router';
export default ({
  state: {
    jwt: localStorage.getItem('jwt') || '',
    socialLink: '',
    errorMsg: '',
  },
  getters: {
    isLoggedIn: (state) => !!state.jwt,
    socialLink: (state) => state.socialLink,
    jwt: (state) => state.jwt,
    errorMsg: (state) => state.errorMsg,
    Authorization(state) {
      if (state.jwt) {
        return `Bearer ${JSON.parse(state.jwt).access}`;
      }
      else {
        return '';
      }

    },
  },
  mutations: {
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg;
    },

    setSocialLink(state, data) {
      state.socialLink = data;
    },

    setToken(state, jwt) {
      state.jwt = JSON.stringify(jwt);
      localStorage.setItem('jwt', JSON.stringify(jwt));
      state.errorMsg = '';
    },

    removeToken(state) {
      localStorage.setItem('jwt', '');
      state.jwt = '';
    },

    updateToken(state, token) {
      if (state.jwt !== '') {
        state.jwt.access = token;
        localStorage.setItem('jwt', JSON.stringify(state.jwt));
      }
    },

  },
  actions: {
    getSocialLink(ctx) {
      this.$http.get(`https://api.dobroagency.ru/auth/social/links/?redirect_uri=https://tracker.dobroagency.ru/auth/login/yandex/`, {})
        .then((response) => {
          ctx.commit('setSocialLink', response.data.yandex);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    jwtCreateSocial(ctx, data) {
      let formData = new FormData();
      formData.append("access_token", data);
      this.$http.post(`https://api.dobroagency.ru/auth/social/jwt/create/`, formData, {})
        .then((response) => {
          ctx.commit('setToken', response.data);
          let url = new URL(window.location.href);
          let next = url.searchParams.get("next");
          router.push(`/${next ? next : ''}`);
        })
        .catch((err) => {
          ctx.commit('setErrorMsg', err.response.data.detail);
        });
    },

    jwtCreate(ctx, data) {
      let formData = new FormData();
      formData.append('email', data.email);
      formData.append('password', data.password);
      this.$http.post(`https://api.dobroagency.ru/auth/jwt/create/`, formData, {})
        .then((response) => {
          ctx.commit('setToken', response.data);
          let url = new URL(window.location.href);
          let next = url.searchParams.get("next");
          router.push(`/${next ? next : ''}`);
        })
        .catch((err) => {
          ctx.commit('setErrorMsg', err.response.data.detail);
        });
    },

    jwtRemove(ctx) {
      ctx.commit('removeToken');
    },

    jwtUpdate(ctx, token) {
      ctx.commit('updateToken', token);
    },

  },
})
