<template>
  <div>
    <button class="button-togle" :class="activate('skills')" @click="showBlock='skills'">Скилы</button>
    <button class="button-togle" :class="activate('grade')" @click="showBlock='grade'">Грейды</button>
    <button class="button-togle" :class="activate('matrix')" @click="showBlock='matrix'">Матрица</button>
    <router-link :to="{name: 'Users'}"><button class="button-togle" :class="activate('users')" @click="showBlock='users'">Пользователи</button></router-link>
      
  <section v-if="getSkillCategoryList && getSkillCategoryList.length">
    <SkillDashboard 
    :SkillCategoryList="getSkillCategoryList" 
    :matrix="getMatrix"
    :showBlock="showBlock"
    />
  </section>
  <section v-else>
    <span>Для вашего пользователя не определена должность. Обратитесь к администратору.</span>
  </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SkillDashboard from '@/components/personal/SkillDashboard.vue'

export default {
  name: 'Personal',
  components: {
    SkillDashboard
  },
    data: () => ({
    showBlock: 'skills',
  }),
  computed: {
    ...mapGetters(['getSkillCategoryList', 'getMatrix']),
  },
  methods: {    
    ...mapActions(['fetchSkillCategoryList', 'fetchMatrix']),
    activate(block) {
      return this.showBlock===block ? 'active' : '';
    },
  },
  async created() {
    let user_id = this.$route.params.id;
    await this.fetchSkillCategoryList({user_id});
    await this.fetchMatrix({user_id});
  }
}
</script>
