export default ({
  state: { departments: [] },
  getters: { getDepartments: state => state.departments },
  mutations: { 
    setDepartments(state, departments) {
      state.departments = departments
    },
  },
  actions: {
    async fetchDepartments(ctx, filter) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }
      fd.append('url', `${process.env.VUE_APP_YANDEX_DIRECTORY}/v6/departments/?fields=id,name&per_page=1000`);
      fd.append('method', 'GET');
      fd.append('headers', JSON.stringify(headers));
      
      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {
          const departments = response.data.result.filter(d => filter.departments.includes(d.id));
          ctx.commit('setDepartments', departments)
      })
    }
  },
})
