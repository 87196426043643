<template>
  <div class="row-skills">
    <div v-for="grade in grades" :key="grade.id" class="col-skills">
      <div class="grade-name" :class="{'recieved_grade': total >= grade.score_min}">
        <div> {{grade.name}} </div>
        </div>
      <div class="grade-name level" :class="{'recieved_grade': total >= grade.score_min}" >
        <div> {{grade.score_min}} </div>
        </div>
    </div>
  </div>
</template>

<script>
import Skills from '@/components/personal/Skills.vue'

export default {
  name: 'Grade',
  components: {
    Skills
  },
  props: {
    total: Number,
    grades: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss">
  $tablet: 1024px;
  $mobile: 767px;

  .main {
    & .row-skills {
      display: flex;
      overflow-x: auto;
      gap: 0 35px;
      flex-direction: column;
      & .col-skills {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 12px;
        @media screen and (max-width: $mobile) {
          min-width: 180px;
          max-width: 180px;
        }
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 30%;
        pointer-events: none;
        z-index: 10;
      }
      @media screen and (max-width: $tablet) {
        width: 100%;
        grid-template-columns: repeat(3, 500px);
        overflow-x: auto;
      }
      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(3, 280px);
        overflow-x: scroll;
        grid-gap: 0 15px;
        &::after {
          bottom: -10px;
        }
      }
      & .grade-name {
        font-size: 14px;
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(254, 227, 140, 0.303);
        color: #000000;
        border-radius: 5px;
        line-height: 12px;
        padding: 9px 12px;
        margin: 0 15px 15px 0;
        opacity: 1;
        max-height: 0;
        transition: all 0.2s ease-in-out;
        text-transform: capitalize;
        visibility: visible;
        min-height: 30px;
        font-weight: bold;
        @media screen and (max-width: $mobile) {
          font-size: 20px;
          max-height: 85px;
          opacity: 0;
          visibility: visible;
          margin-bottom: 10px;
        }
      }
      & .level {
        width: 50px;
      }
      & .recieved_grade{
      background-color: rgb(252, 228, 99)
      }
    }
    &.expand {
      & .grade-name {
        opacity: 1;
        visibility: visible;
        @media screen and (max-width: $mobile) {
          margin-bottom: 10px;
        }
      }
    }
  }
</style>