export default ({
  state: { 
    user: [],
    users: [],
    avatars: [],
  },
  getters: { 
    getUser: state => state.user,
    getUsers: state => state.users,
    getAvatars: state => state.avatars, 
  },
  mutations: { 
    setUser(state, user) {
      state.user = user
    },
    setUsers(state, users) {
      state.users = users
    },
    setAvatars(state, avatars) {
      state.avatars = avatars
    },
  },
  actions: {
    async fetchUser(ctx) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }
      fd.append('url', `${process.env.VUE_APP_YANDEX_TRACKER}/v2/myself`);
      fd.append('method', 'GET');
      fd.append('headers', JSON.stringify(headers));

      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {
          ctx.commit('setUser', response.data);
      })
    },
    async fetchUsers(ctx, filter) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;

      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }
      
      let queryObj = {
        fields: ['id', 'name', 'position', 'department_id', 'is_enabled', 'email', 'nickname'],
        per_page: 1000
      };
      let query = new URLSearchParams(queryObj).toString();

      fd.append('url', `${process.env.VUE_APP_YANDEX_DIRECTORY}/v6/users/?${query}`);
      fd.append('method', 'GET');
      fd.append('headers', JSON.stringify(headers));
      
      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {
          const users = response.data.result.filter(u => u.is_enabled !== false && filter.departments.includes(u.department_id));
          ctx.commit('setUsers', users)
      })
    },
    async fetchAvatars(ctx) {
      this.$http.get('/auth/user/')
        .then(response => {
          const users = response
          ctx.commit('setAvatars', users.data)
      })
    }
  },
})
