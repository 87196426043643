export default ({
  state: { logTimes: [] },
  getters: { getLogTimes: state => state.logTimes },
  mutations: { 
    setLogTimes(state, times) {
      state.logTimes = times
    },
    clearLogTimes(state) {
      state.logTimes = []
    },
  },
  actions: {
    async fetchLogTimes(ctx, issue_id) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }
      fd.append('url', `${process.env.VUE_APP_YANDEX_TRACKER}/v2/issues/${issue_id}/worklog`);
      fd.append('method', 'GET');
      fd.append('headers', JSON.stringify(headers));
      
      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {
          ctx.commit('setLogTimes', response.data)
      })
    },
    async playPauseLogTimes(ctx, { issue_id, start, duration }) {
      const fd = new FormData();
      let token = process.env.VUE_APP_BUILD_TYPE === 'prod' ? JSON.parse(localStorage.getItem('jwt')).raw_data.yandex_token : process.env.VUE_APP_TOKEN;
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': `OAuth ${token}`,
        'X-Org-Id': process.env.VUE_APP_ORG_ID,
      }
      let payload = {
        "start": start,
        "duration": duration,
      };

      fd.append('url', `${process.env.VUE_APP_YANDEX_TRACKER}/v2/issues/${issue_id}/worklog`);
      fd.append('method', 'POST');
      fd.append('headers', JSON.stringify(headers));
      fd.append('data', JSON.stringify(payload));

      this.$http.post('/auth/yandex_request/', fd)
        .then(response => {})
    },
    async clearStateLogTime(ctx) {
      ctx.commit('clearLogTimes')
    },
  },
})