export default {
  methods: {
    getUserAvatar(email) {
      let avatar = '';
      if ( this.getAvatars.length ) {
        avatar = this.getAvatars.find(a => a.email === email);
      }
      if ( avatar ) {
        return avatar.avatar;
      } else {
        return 'https://avatars.yandex.net/get-yapic/0/0-0/islands-200'
      }
      
    }
  }
}