export default ({
  state: { 
    setting: {
      countDays: 7,
      heightRow: 120,
      widthAside: 300,
      shedule: ['0', " ", " ", " ", " ",],
      timeStamps: ['0', " ", " ", " ", " "],
      shadowTimes: ['0'],
      zoom: 1,
      scaleOptions: [
        { label: '1 Day',   zoom: 0.5 },
        { label: '7 Days',  zoom: 1 },
        { label: '14 Days', zoom: 2 },
        { label: '30 Days', zoom: 4 },
      ],
    },
    issue_filter: {
      // 33 Develop
      // 35 Sasha
      // 37 Copyright
      // 38 Pasha 
      // 80 School
      // ? Больничные и отпуска
      filter: {
        start: "notEmpty()",
        deadline: "notEmpty()",
        ispolniteli: "notEmpty()",
        // deadline: "quarter()",
        // created: "01-12-2021..31-12-2022"
        // "boards": [33, 35, 37, 38, 80,],
      },
      query: "Created:01-12-2021..31-12-2022",
    },
    status_groups: [
      ["Open", "Pause", "inProgress", "needInfo", "closed", "testing", "new", "naStoroneKlienta"],
      ["needInfo", "closed", "testing", "naStoroneKlienta"],
      ["Open", "Pause", "inProgress", "needInfo", "new"],
    ]
  },
  getters: { 
    Config: state => state.setting,
    getStatusesForFilter: state => state.status_groups,
    IssueFilter: state => { 
      const statusGroupID = localStorage.getItem('status_group');
      state.issue_filter.filter.status = statusGroupID ? state.status_groups[statusGroupID] : state.status_groups[0];
      return state.issue_filter;
    },
  }
})
