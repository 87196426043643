<template>
  <div class="_relative">
    <section class="top _mb-4">
      <Navigation />

      <div class="_flex">
        <div class="app-select_wrapper _mr-2">
          <el-select v-model="zoom" popper-class="app-select" placeholder="Масштаб">
            <el-option
              v-for="item in scaleOptions"
              :key="item.zoom"
              :label="item.label"
              :value="item.zoom">
            </el-option>
          </el-select>
        </div>

        <div class="app-select_wrapper">
          <el-select v-model="status_id" popper-class="app-select" placeholder="">
            <el-option
              v-for="item in statusOptions"
              :key="item.status_id"
              :label="item.label"
              :value="item.status_id">
            </el-option>
          </el-select>
        </div>
      </div>
    </section>
    
    <section class="chart" :key="countDays" @key="zoom">
      <section :key="$route.params.id"
        class="gantt gantt_noscrollbar" 
        :class="[{'gantt_month': zoom === 4}, 
          {'apple-chart': navig === 'Apple Computer, Inc.' }]"
        >
        <svg
          @pointerdown="ctrlCursorOfChart($event)"
          @pointerup="ctrlCursorOfChart($event)"
          @wheel="ctrlCursorOfChart($event)">
        </svg>

        <svg style="height: 0; width: 0;">
          <defs>
            <linearGradient id="Gradient-1" x1="2%" y1="1%" x2="3.5%" y2="4%">
              <stop offset="0.05" stop-color="#ff0000" />
              <stop offset="0.21" stop-color="#000" />
            </linearGradient>

            <linearGradient id="repeat" 
              href="#Gradient-1" 
              spreadMethod="repeat" 
            />
          </defs>
        </svg>
      </section>

      <transition name="fade">
        <Preloader :mode="1" v-if="isLoaded" />
      </transition>
    </section>

    <transition name="fade">
      <ChartContextMenu v-show="isShowedContextMenu" 
        @ctrl="changeStatusTask"
        @close="closeContextMenu"
        @loading="startLoading"
        :tasks="tasks" 
        :isShow="isShowedContextMenu"
        :id="barIdForChangeStatus" />
    </transition>

    <transition name="fade">
      <Tooltip :tooltip="tooltip" />
    </transition>

    <transition name="fade">
      <fixed-overlay v-show="popUp.isShow" @close="closePopUp">
        <transition name="bounce">
          <IssueCard v-if="popUp.isShow"
            :data="popUp.data" 
            @close="closePopUp" 
          />
        </transition>
      </fixed-overlay>
    </transition>
  </div>
</template>

<script>
import gantt from '@/mixins/gantt'
import { mapGetters, mapActions } from 'vuex'

import Tooltip from '@/components/ui/Tooltip'
import IssueCard from '@/components/ui/IssueCard'
import Preloader from '@/components/ui/Preloader'
import Navigation from '@/components/ui/Navigation'
import ChartContextMenu from '@/components/ui/ChartContextMenu'

import FixedOverlay from '@/components/slots/FixedOverlay'

export default {
  name: 'Chart',
  mixins: [gantt],
  props: {
    tasks: Array,
    users: Array,
  },
  components: { 
    Tooltip,
    IssueCard,
    Preloader,
    Navigation, 
    ChartContextMenu,

    FixedOverlay,
  },
  data: () => ({
    width: window.innerWidth,
    countDays: 7,
    heightRow: 120,
    widthAside: 300,
    shedule: ['0', " ", " ", " ", " ",],
    timeStamps: ['0', " ", " ", " ", " "],
    shadowTimes: ['0'],
    isShowedContextMenu: false,
    barIdForChangeStatus: '',
    zoom: 1,
    update: 0,
    status_id: localStorage.getItem('status_group') ? Number(localStorage.getItem('status_group')) : 0,
    scaleOptions: [
      { label: '1 Day',   zoom: 0.5 },
      { label: '7 Days',  zoom: 1 },
      { label: '14 Days', zoom: 2 },
      { label: '30 Days', zoom: 4 },
    ],
    isLoaded: false,

    statusOptions: [
      { label: 'All',    status_id: 0 },
      { label: 'Closed', status_id: 1 },
      { label: 'Opened', status_id: 2 },
    ],
    tooltip: {
      isShow: false,
      x: 0,
      y: 0,
      data: {}
    },

    popUp: {
      isShow: false,
      data: {}
    }
  }),
  computed: {
    ...mapGetters([
      'Config', 
      'getUser', 
      'IssueFilter', 
      'getStatusesForFilter',
      'getCalendar',
    ]),
    today: function() {
      return new Date(this.$moment().add('-1', 'days'))
    },
    svgWidth: function() {
      return this.width
    },
    mindate: function() {
      return this.$moment(this.today).add('-3', 'days')._d
    },
    maxdate: function() {
      return this.$moment(this.mindate).add(this.countDays, 'days');
    },
    navig: function() { return navigator.vendor },
  },
  methods: {
    ...mapActions(['updateDateIssue', 'fetchTasks']),
    changeStatusTask(ev) {
      if ( ev !== 'testing' && ev !== 'needInfo' && ev !== 'check' ) {
        this.isShowedContextMenu = false;
        this.isLoaded = true;
      }
    },
    closeContextMenu() {
      this.barIdForChangeStatus = '';
      this.isShowedContextMenu = false;
    },
    startLoading() {
      this.isLoaded = true;
    },
    scaleChart() {
      switch(this.zoom) {
        case 0.5:
          this.countDays = 3;
          this.timeStamps =  ['0', " ", " ", " ", " "];
          this.shadowTimes = ['0'];
          break;
        case 1:
          this.countDays = 7;
          this.timeStamps =  ['0', " ", " ", " ", " "];
          this.shadowTimes = ['0'];
          break;
        case 2:
          this.countDays = 14;
          this.timeStamps =  ['0', " "];
          this.shadowTimes = ['0',];
          break;
        case 4:
          this.countDays = 30;
          this.timeStamps =  [];
          this.shadowTimes = [];
          break;
        default:
          this.countDays = 7;
          this.timeStamps =  ['0', " ", " ", " ", " "];
          this.shadowTimes = ['0'];
          break;
      }
    },
    setHeightChart() {
      const wrapperChart = document.querySelector('.chart');
      const chart = document.querySelector('.gantt');
      const topOffset = wrapperChart.getBoundingClientRect().y;
      const screenHeight = document.body.clientHeight;
      const height = screenHeight - topOffset - 80;
      
      wrapperChart.style.maxHeight = `${height}px`;
      chart.style.maxHeight = `${height}px`;
    },
    closePopUp() {
      this.popUp.isShow = false;
      this.popUp.data = {};
    }
  },
  async mounted() {
    this.drowChart();
    document.addEventListener('click', e => {
      if ( !e.target.closest('.menu') ) {
        this.closeContextMenu();
      }
    });
    document.addEventListener('wheel', () => {
      if ( this.isShowedContextMenu ) this.isShowedContextMenu = false;
    });
    if ( localStorage.getItem('scale') && 
      localStorage.getItem('scale') !== this.zoom &&
      this.scaleOptions.find(obj => obj.zoom === Number(localStorage.getItem('scale')))) {
      this.zoom = Number(localStorage.getItem('scale'));
      this.scaleChart(); 
    };
    this.setHeightChart();
  },
  watch: {
    zoom() { 
      localStorage.setItem('scale', this.zoom);
      this.scaleChart(); 
      this.setHeightChart();
    },
    async status_id() {
      localStorage.setItem('status_group', this.status_id);
      this.isLoaded = true;
      let filter = {...this.IssueFilter.filter};
      filter.status = this.getStatusesForFilter[this.status_id];
      if ( this.status_id === 2 ) delete filter.created;
      await this.fetchTasks({filter: filter});
    },
    countDays() {
      setTimeout(() => { this.drowChart() }, 10)
      this.setHeightChart();
    },
    tasks() {
      document.querySelector('svg').innerHTML = '';
      setTimeout(() => { this.drowChart() }, 10)
      this.setHeightChart();
    },
    users() {
      document.querySelector('svg').innerHTML = '';
      setTimeout(() => { this.drowChart() }, 10)
      this.setHeightChart();
    },
    isLoaded() {
      if ( this.isLoaded ) {
        setTimeout(() => {
          this.isLoaded = false;
        }, 3000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
._relative { position: relative; }
._mb-4 { margin-bottom: 16px; }
._mr-2 { margin-right: 8px; }
._flex { display: flex; }

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}
</style>
