<template>
  <div class='modal-skill-box' v-if="getSkillInfo && getSkillInfo.skill && getSkillInfo.skill.name">
    <div class="overlay">
      <div class="modal-box" >
        <button @click="$emit('closeModal')" class="button close">x</button>

        <div v-if="getSkillInfo" class="info-header">
          <div class="name-skill">{{getSkillInfo.skill.name}}</div>
         
          <div v-for="(item, i) in getStars(getSkillInfo.level)" :key="i">
              <span @click="record(getSkillInfo.id, i, getSkillInfo.level)" class="button record" :class="item.status ? 'star__true' : 'star__false'"  v-html="item.symbol"></span>
          </div>          
        </div>

        <p class="desc-skill">{{getSkillInfo.skill.description}}</p>
        <div v-if="getSkillInfo.skill.materials">
          <div class="title-link">Ссылки на материалы</div>
          <a
            v-for="mat in getSkillInfo.skill.materials" :key="mat.id" 
            :href="`${mat.link}`" target="_blank" class="link"
          >
            <span>{{mat.name}}</span>
            <font-awesome-icon icon="external-link-alt" />
          </a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ModalBox',
  props: {
    skill: {
      type: Object,
      required: true
    },
    category_id: {
      type: Number,
    }
  },
  computed: {
    ...mapGetters(['getRec', 'getSkillInfo']),
    Rec () {
      return this.getRec;
    },
  },
  methods: {
    getStars(count) {
      let arr = []
      for (let i=0; i<count; i++) {
        arr.push({symbol: '&#9733;', status:  true})
      }
      for (let i=0; i<(5-count); i++) {
        arr.push({symbol: '&#9734;', status: false})
      }
      return arr;
    },
    ...mapActions(['fetchRec', 'fetchSkillInfo']),
    async record(skill_id, level, before_level=0) {
      if (before_level === level + 1) {
        level = 0
      }
      else {
        level +=1;
      }
      await this.fetchRec({skill_id: skill_id, level, category_id: this.category_id, user_id: this.$route.params.id});
    },
  },

  mounted() {
    let user_id = this.$route.params.id;
    this.fetchSkillInfo({skill_id: this.skill.id, user_id});
    var vm = this;
    window.addEventListener ('keyup', function(event) {
      if (event.key === "Escape") {
        vm.$emit("closeModal");
      }
    });
  }
}
</script>



<style lang="scss">

  $mobile: 767px;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1d2734b3;
    z-index: 1000;
    animation: 0.3s opacitymodal forwards;
  }
  @keyframes opacitymodal {
    0% {
     
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .main .modal-box {
    position: relative;
    width: 605px;
    max-height: fit-content;
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    color: black;
    & .button {
      margin: 0;
      font-size: 16px;
      background-color: transparent;
      &.close {
        position: absolute;
        width: 44px;
        height: 44px;
        right: 5px;
        top: 5px;
        font-size: 25px;
        font-weight: bold;
        background-color: transparent;
        padding: 0;
        @media screen and (max-width: $mobile) {
          height: 24px;
        }
      }
    }
    & .info-header {
      display: flex;
      align-items: center;
      width: 100%;
      padding-bottom: 13px;
      margin-bottom: 17px;
      border-bottom: 1px solid #e3eaf0;
      & .name-skill {
        margin-right: 10px;
        font-size: 20px;
        font-weight: bold;
      }
    }
    & .desc-skill {
      font-size: 14px;
    }
    & .title-link {
      font-weight: bold;
      font-size: 16px;
      margin: 20px 0 19px;
    }
    & .link {
      display: flex;
      width: fit-content;
      font-size: 14px;
      line-height: 16px;
      padding: 9px 6px 7px 12px;
      margin-top: 15px;
      font-weight: bold;
      color: #315efb;
      text-decoration: none;
      border: 1px solid #e3eaf0;
      border-radius: 3px;
      & span {
        margin-right: 12px;
      }
    }
    @media screen and (max-width: $mobile) {
      padding: 40px 20px 20px;
      margin: 0 20px;
    }
  }
  .star__true {
    color: orange;
    cursor: pointer;
  }

  .star__false {
    color: rgb(101, 101, 101);
    cursor: pointer;
  }

</style>