export default ({
  state: { 
    SkillCategoryList: [],
    SkillInfo: {},
    matrix: [],
    Rec: "",
    person: {},
    persons: [],

  },
  getters: {
    getPerson: (state) => state.perons,
    getPersons: (state) => state.persons,
    getRec: state => state.Rec,
    getSkillCategoryList: state => state.SkillCategoryList,
    getSkillInfo: state => state.SkillInfo,
    getMatrix: state => state.matrix,
  },
  mutations: {
    setPerson(state, data){
      state.person = data;
    },
    setPersons(state, data){
        state.persons = data;
    },
    setRec(state, Rec) {
      state.Rec = Rec
    },
    setMatrix(state, matrix) {
      state.matrix = matrix;
    },
    setSkillCategoryList(state, SkillCategoryList) {
      state.SkillCategoryList = SkillCategoryList;
    },
    setSkillInfo(state, SkillInfo) {
      state.SkillInfo = SkillInfo;
    },
  },
  actions: {
    fetchPerson(ctx){
      this.$http.get(`/auth/user/me/`, {})
      .then((response) => {
          ctx.commit('setPerson', response.data);
      })
      .catch((err) => {
          console.log(err);
      });
  },
  fetchPersons(ctx){
    this.$http.get(`/auth/user/`, {})
          .then((response) => {
              ctx.commit('setPersons', response.data);
          })
          .catch((err) => {
              console.log(err);
          });
  },

    fetchMatrix(ctx, data) {
      let url = `/api/skill/matrix/`
      if (data && data.user_id) {
        url += `?user_id=${data.user_id}`;
      }
      this.$http.get(url)
        .then(response => {
          ctx.commit('setMatrix', response.data)
      })
    },
    async fetchSkillCategoryList(ctx, data) {
      let url = '/api/skill/category/';
      if (data && data.user_id) {
        url += `?user_id=${data.user_id}`;
      }
      this.$http.get(url)
        .then(response => {
          ctx.commit('setSkillCategoryList', response.data)
      })
    },

    async fetchSkillInfo(ctx, data) {
      let url = `/api/skill/category-skill/${data.skill_id}/`
      if (data && data.user_id) {
        url += `?user_id=${data.user_id}`;
      }
      this.$http.get(url)
        .then(response => {
          ctx.commit('setSkillInfo', response.data)
      })
    },

    clearSkillInfo(ctx) {
      ctx.commit('setSkillInfo', {})
    },

    async fetchRec({dispatch}, {skill_id, level, user_id=''}) {
      let fd = new FormData();
      fd.append('level', level);
      this.$http.post(`/api/skill/category-skill/${skill_id}/check/?user_id=${user_id}`, fd)
        .then(response => {
          if (response.status) {
            console.log(skill_id);
            dispatch('fetchSkillInfo', {skill_id, user_id});
            dispatch('fetchSkillCategoryList', {user_id});
            dispatch('fetchMatrix', {user_id});

          }
      })
    }
  },
})
