<template>
  <div v-if="tooltip.isShow" 
    class="tooltip"
    :style="`left: ${tooltip.x + 20}px; top: ${tooltip.y - 10}px`">
      <h3 class="tooltip__title">{{ tooltip.data.sponsor }}</h3>
      <p class="tooltip_txt">{{ tooltip.data.title }}</p>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: { tooltip: Object }
}
</script>

<style lang="scss" scoped>
.tooltip {
  position: fixed;
  background-color: #000;
  padding: 15px;
  border-radius: 8px;
  max-width: 350px;
  box-shadow: 0px 0px 5px 0px rgba(249, 250, 251, 0.2);

  &__title {
    font-weight: 500;
    margin-bottom: 16px;
  }
}
</style>
