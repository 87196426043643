export default {
  computed: {
    reverseProjects: function() {
      return this.projects.slice().reverse()
    }
  },
  methods: {
    // Управление курсором
    ctrlCursorOfChart(e) {
      const grabWrapper = document.querySelector('.gantt_noscrollbar');
      if ( !grabWrapper ) return;
      if ( e.type === 'pointerdown' ) {
        grabWrapper.style.cursor = 'grabbing'
      }
      if ( e.type === 'pointerup' ) {
        grabWrapper.style.cursor = 'grab'
      }
      if ( e.type === 'wheel' ) {
        const ganttWrapper = document.querySelector('.gantt');
        ganttWrapper.classList.remove('gantt_noscrollbar');
        grabWrapper.style.cursor = 'progress';

        setTimeout(() => {
          grabWrapper.style.cursor = 'grab';
          ganttWrapper.classList.add('gantt_noscrollbar');
        }, 550)
      }
    },
    // Переходы 
    goToTask(elem) { window.open(elem.link, '_blank') },
    goToUser(elem) { console.log(elem) },
    // showContextMenu() {
    //   const e = this.$d3.event;
    //   e.preventDefault();
      
    //   const task_id = e.target.closest('.group-rect').dataset.id;
    //   this.barIdForChangeStatus = task_id;
    //   this.isShowedContextMenu = true;
    //   const menu = document.querySelector('.menu');
    //   menu.style.left = `${e.offsetX + 30}px`;
    //   menu.style.top = `${e.clientY}px`;
    // },
    // Handlers для отрисовки элементов svg
    calcWidthRect(d) {
      const k = 1;
      let widthDays = ((this.$d3.timeDay.count(this.$moment(d.startDate)._d, this.$moment(d.endDate)._d) + k) * (this.svgWidth - this.widthAside) / this.countDays);
      if ( widthDays > 0 ) {
        return widthDays;
      } else {
        return;
      }
    },
    showPopUp(data) {
      this.hiddenTooltip();
      this.popUp.isShow = true;
      this.popUp.data = data;
    },
    dragAxisX(el, t) {
      const group = el.closest('.group-rect');
      const title = group.querySelector('.title');
      const bar = group.querySelector('.bar');
      const barX = Number(bar.getAttribute('x'));
      const width = Number(bar.getAttribute('width'));
      if ( width > 550 ) {
        if ( t.x < 0 ) {
          if ( Math.abs(t.x) + 310 > Math.abs(barX) + 20 ) {
            el.setAttribute('x', Math.abs(t.x) + 310);
            title.setAttribute('x', Math.abs(t.x) + 310);
          } else {
            el.setAttribute('x', barX + 20);
            title.setAttribute('x', barX + 20);
          }
        } else {
          if ( barX + 20 < 0 && t.x < Math.abs(barX) + 310 ) {
            el.setAttribute('x', -t.x + 310);
            title.setAttribute('x', -t.x + 310);
          } else {
            el.setAttribute('x', barX + 20);
            title.setAttribute('x', barX + 20);
          }
        }
      }
    },
    showStatus(status) {
      switch(status) {
        case 'in_progress':
          return 'В работе'
          case 'draft':
            return 'Черновик'
        default:
          return 'Неизвестный статус'
      }
    },
    activateDate() {
      const ev = this.$d3.event;
      const el = ev.target.closest('.tick');
      
      const activeTicks = document.querySelectorAll('.tick_active');
      activeTicks.forEach(t => {
        t.classList.remove('tick_active')
      });
      el.classList.add('tick_active');
    },
    // Main метод для отрисовки всего графика
    drowChart() {
      const self = this;
      const svg = this.$d3.select('svg');
      const height = this.projects.length * this.heightRow;
      svg.attr('height', height + 80);
          
      /*
      *
      * Dates X Axis
      * 
      */
      const x = this.$d3.scaleTime()
        .domain([self.mindate, self.maxdate])
        .range([self.widthAside, self.svgWidth]);

      const wrapX = svg.append('svg')
        .attr('class', 'wrapper--x');

      const xAxis = this.$d3.axisTop(x)
        .ticks(self.countDays)
        .tickPadding(10)
        .tickSize(height - 20)
        .tickFormat(function (d) {
          if ( self.$moment(d).day() === 6 || self.$moment(d).day() === 0 ) {
            self.$d3.select(this)
              .attr('fill', '#eb5757');
            const weekendG = this.closest('.tick');
            if ( !weekendG.querySelector('.weekend-rect') ) {
              self.$d3.select(weekendG)
              .attr('class', 'tick weekend-group')
              .append('rect')
              .attr('class', 'weekend-rect')
              .attr('y', -height + 55)
              .attr('height', height - 55)
              .attr('width', (self.svgWidth - self.widthAside) / self.countDays)
              .attr('fill', 'url(#repeat)')
              .attr('style', 'opacity: .2');
            }
              
            return self.$moment(d).format('DD.MM')
          } else if ( self.$moment(d).format('YYYY-MM-DD') === self.$moment().format('YYYY-MM-DD') ) {
            const parent = self.$d3.select(this.closest('.tick'));
            const parentHTML = this.closest('.tick');
            if ( !parentHTML.querySelector('.axis--x-wrapper') ) {
              const todayG = parent.attr('class', 'tick axis--x-today');
              if ( !parentHTML.querySelector('rect') ) {
                parent.append('rect')
                  .attr('class', 'weekend-rect')
                  .attr('y', -height + 55)
                  .attr('height', height - 55)
                  .attr('width', (self.svgWidth - self.widthAside) / self.countDays)
                  .attr('fill', '#a16d2a')
                  .attr('style', 'opacity: .075');
              }
            }

            self.$d3.select(this)
              .attr('fill', '#a16d2a');

            return self.$moment(d).format('DD.MM')
          } else {
            self.$d3.select(this)
              .attr('fill', '#fff');

            return self.$moment(d).format('DD.MM')
          }
        });
        // .on('click', e => this.activateDate());

      const gX = wrapX.append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .attr('class', 'axis axis--x')
        .call(xAxis)
        .on('click', self.activateDate)
        
      //> END X 

      /*
      *
      * BARS
      * 
      */
      const wrap = svg.append('svg')
        .attr('x', 0)
        .attr('y', () => {
          if ( navigator.vendor ) {
            return 40;
          } else {
            return -height + 40;
          }
        })
        .attr('height', height)
        .attr('width', '100%')
        .attr('transform', 'translate(0,' + height + ')')
        .attr('class', 'parent-bars');
        
      const bars = wrap.selectAll('.bar')
          .data(self.projects)
          .enter()
          .append('g');

      const gBar = svg.selectAll('.parent-bars g');
      gBar.data(self.projects)
        .attr('data-id', function(d) { return d.id })
        .attr('class', function(d) { return 'group-rect' });
      
      // Маска чтобы текст не выходил за пределы бара
      bars.append('clipPath')
        .attr('id', (d, i) => { return `rect-${i}` })
        .append('rect')
          .attr('x', (d) => { return x(self.$moment(d.startDate)._d) })
          .attr('y', d => {
            const label = self.projects.find(obj => obj.id === d.id);
            const index = self.projects.indexOf(label)
            return index * self.heightRow;
          })
          .transition()
          .duration(2000)
          .delay(150)
          .attr('height', 64)
          .attr('width', d => {
            let width = this.calcWidthRect(d);
            return width - 1;
          });
          
      // Основное тело бара
      bars.append('rect')
          .attr('class', 'bar')
          .attr('x', (d) => {
            const _start = this.$moment(this.$moment(d.startDate)._d);
            return x(_start)
          })
          .attr('y', d => { 
            const label = self.projects.find(obj => obj.id === d.id);
            const index = self.projects.indexOf(label);
            return index * self.heightRow; 
          })
          .transition()
          .duration(2000)
          .delay(150)
          .attr('height', 64)
          .attr('width', d => {
            let width = this.calcWidthRect(d);
            return width;
          })
          .attr('fill', '#343434')
          
      // Левый бордер бара
      bars.append('rect')
        .attr('class', 'bar-color')
        .attr('x', (d) => { return x(this.$moment(d.startDate)._d) })
        .attr('y', d => {
          const label = self.projects.find(obj => obj.id === d.id);
          const index = self.projects.indexOf(label);
          return index * self.heightRow
        })
        .attr('height', 64)
        .attr('width', 4)
        .attr('rx', 4)
        .attr('ry', 4)
        .attr('fill', d => { return d.color || '#0f7e5d' });

      // Правый бордер для изменения ширины бара 
      bars.append('rect')
        .attr('class', 'bar-change-date')
        .attr('x', (d) => {
          return x(self.$moment(this.$moment(d.endDate)._d).add('1', 'days')) - 6
        }) 
        .attr('y', d => {
          const label = self.projects.find(obj => obj.id === d.id);
          const index = self.projects.indexOf(label)
          return index * self.heightRow;
        })
        .attr('height', 64)
        .attr('width', 6)
        .attr('rx', 3)
        .attr('fill', '#989898');

      // Ссылка на таск
      const texts = bars.append('g')
        .attr('clip-path', (d, i) => { return `url(#rect-${i})`});

      texts.append('text')
        .data(self.projects)
        .attr('class', 'sponsor')
        .attr('x', (d) => { return x(this.$moment(d.startDate)._d) + 20 })
        .attr('y', d => {
          const label = self.projects.find(obj => obj.id === d.id);
          const index = self.projects.indexOf(label)
          return index * self.heightRow + 25;
        })
        // .attr('data-link', d => { return d.link })
        .text(d => { return d.name })
        // .on('click', el => self.goToTask(el));
      
      // Заголовок таска
      texts.append('text')
        .data(self.projects)
        .attr('class', 'title')
        .attr('x', (d) => { return x(this.$moment(d.startDate)._d) + 20 })
        .attr('y', d => {
          const label = self.projects.find(obj => obj.id === d.id);
          const index = self.projects.indexOf(label)
          return index * self.heightRow + 50;
        })
        .text(d => { return `${self.showStatus(d.status)}. (c ${self.$moment(d.startDate).format('D MMM YYYY')} по ${self.$moment(d.endDate).format('D MMM YYYY')})` });
      //> END BARS

      /*
      *
      * Dates Y Axis
      * 
      */
      const y = this.$d3.scaleBand()
        .domain(self.reverseProjects.map(d => d.key))
        .rangeRound([height + 30, 32])

      const yAxis = this.$d3.axisRight(y)
        .ticks(self.projects.length)
        .tickSize(self.widthAside)
        .tickPadding(-300) 

      const wrapY = svg.append('svg')
        .attr('class', 'wrapper--y')
        .attr('width', self.widthAside);

      const gY = wrapY.append('g')
        .attr('class', 'axis axis--y')
        .style('font-size', '14px')
        .call(yAxis)
        .selectAll('text')
        .attr('class', 'username')

      wrapY.selectAll('.tick')
        .selectAll('line')
        .remove();

      const ticks = wrapY.selectAll('.tick');
      ticks.append('text')
        .data(self.reverseProjects)
        .style('fill', '#898989')
        .attr('x', 0)
        .attr('y', 25)
        .text(d => { return d.lead ? d.lead.display : ''});
      //> END Y 

      /*
      *
      * Move && Zoom
      * 
      */
      const zoom = this.$d3.zoom()
        .scaleExtent([1, 1])
        .on('zoom', zoomed)

      function zoomed() {
        const t = self.$d3.event.transform;
        const sx = t.rescaleX(x);

        gX.call(xAxis.scale(sx));
        bars.style('transform', `translateX(${t.x}px)`)

        const sponsorList = document.querySelectorAll('.sponsor');
        sponsorList.forEach(el => self.dragAxisX(el ,t))
      }

      svg.call(zoom);
      //> END M&&Z

      /*
       *
       * Change width && position Bars
       * 
      */
      let xStart = 0;
      let elID = '';
      const dragXBar = this.$d3.drag()

      .on('drag', function () {
          const parent = this.closest('.group-rect');
          elID = parent.dataset.id;
          let issue = self.tasks.find(t => t.id === elID);
          if ( issue.author !== Number(self.getUser.uid) ) return;
          const bar = document.querySelector(`.group-rect[data-id="${elID}"] .bar`);
          const elWidth = Number(bar.getAttribute('width'))
          
          const elX = Number(bar.getAttribute('x'));
          const mouseX = self.$d3.event.x;
          const deltaX = mouseX - elX;

          const listIssues = document.querySelectorAll('.group-rect');
          listIssues.forEach(t => {
            if ( t.dataset.id !== elID ) {
              t.style.opacity = 0.6;
            }
          })

          self.$d3.select(`.group-rect[data-id="${elID}"] .bar`)
              .attr('x', self.$d3.event.x);
          self.$d3.select(`.group-rect[data-id="${elID}"] .bar-color`)
              .attr('x', self.$d3.event.x);
          self.$d3.select(`.group-rect[data-id="${elID}"] .bar-change-date`)
              .attr('x', () => {
                return self.$d3.event.x + elWidth - 6
          });
          self.$d3.select(`.group-rect[data-id="${elID}"] clipPath`)
              .attr('x', self.$d3.event.x);
          self.$d3.select(`.group-rect[data-id="${elID}"] clipPath > rect`)
              .attr('x', self.$d3.event.x);
          self.$d3.select(`.group-rect[data-id="${elID}"] .title`)
              .attr('x', self.$d3.event.x + 15);
          self.$d3.select(`.group-rect[data-id="${elID}"] .sponsor`)
              .attr('x', self.$d3.event.x + 15);
      })
      .on('start', function() {
        xStart = self.$d3.event.x;
      })
      .on('end', async function() {
        const deltaX = xStart - self.$d3.event.x;
        const deltaDays = -1 * Math.round(deltaX / ((self.svgWidth - self.widthAside) / self.countDays));
        if (deltaDays) {
          let issue = self.tasks.find(t => t.id === elID);
          if ( issue.author !== Number(self.getUser.uid) ) return;
          self.isLoaded = true;
          const new_deadline = self.$moment(issue.times.ending_time)
            .add(deltaDays, 'days')
            .format('YYYY-MM-DD');
          const new_start = self.$moment(issue.times.starting_time)
            .add(deltaDays, 'days')
            .format('YYYY-MM-DD');
          const obj = {
            "start": new_start,
            "deadline": new_deadline,
          };
          await self.updateDateIssue({
            issue_id: elID,
            data: obj,
          });
        }
      })
  
      dragXBar(svg.selectAll('.bar-color'));

      let startDrag = 0,
          finishDrag = 0;

      let grabWrapper = '',
          parent = '',
          bar = '',
          ID = '';

      const dragWidthBar = this.$d3.drag()
        .on('start', function() {
          startDrag = self.$d3.event.x;
          grabWrapper = document.querySelector('.gantt_noscrollbar');
          parent = this.closest('.group-rect')
          ID = parent.dataset.id;
          bar = document.querySelector(`.group-rect[data-id="${ID}"] .bar`);
          
          grabWrapper.style.cursor = 'col-resize';
          bar.style.cursor = 'col-resize';
        })
        .on('end', async function() {
          finishDrag = self.$d3.event.x;
          const clip = document.querySelector(`.group-rect[data-id="${ID}"] clipPath rect`);
          const barWidth = Number(bar.getAttribute('width')) + (self.$d3.event.x - startDrag);
          grabWrapper.style.cursor = 'default';
          bar.style.cursor = 'default';

          const deltaX = finishDrag - startDrag;
          if ( barWidth <= 0 ) return;
          const deltaDays = Math.round(deltaX / ((self.svgWidth - self.widthAside) / self.countDays));

          let issue = self.tasks.find(t => t.id === ID);
          if ( issue.author !== Number(self.getUser.uid) ) return;
          
          const new_deadline = self.$moment(issue.times.ending_time)
            .add(deltaDays, 'days')
            .format('YYYY-MM-DD');
          const obj = {  "deadline": new_deadline, };
          self.$d3.select(`.group-rect[data-id="${ID}"] .bar-change-date`)
              .attr('x', () => {
                return self.$d3.event.x - 3
          });
          bar.setAttribute('width', barWidth);
          clip.setAttribute('width', barWidth - 1);
          await self.updateDateIssue({
            issue_id: ID,
            data: obj,
          });
          setTimeout(() => self.isLoaded = true, 350)
        })

        dragWidthBar(svg.selectAll('.bar-change-date'));
      //> END Change width && position Bars

      /*
       * Скролл && Скроллбар
       *
      */
      const aside = document.querySelector('.wrapper--y');
      const wrapperChart = document.querySelector('.gantt');

      aside.addEventListener('wheel', e => { 
        e.stopPropagation();
        if ( this.isShowedContextMenu ) this.isShowedContextMenu = false;
      });
      aside.addEventListener('mouseenter', () => {
        if ( wrapperChart && wrapperChart.classList.contains('gantt_noscrollbar') ) {
          wrapperChart.classList.remove('gantt_noscrollbar');
        }
      })
      aside.addEventListener('mouseleave', () => { 
        if ( wrapperChart && !wrapperChart.classList.contains('gantt_noscrollbar') ) {
          wrapperChart.classList.add('gantt_noscrollbar');
        }
      })
      //> END
    }
  },
}
