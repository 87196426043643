<template>
  <section>
    <section class="chart" :key="countDays" @key="zoom">
      <section :key="$route.params.id"
        class="gantt gantt_noscrollbar" 
        :class="[{'gantt_month': zoom === 4}, 
          {'apple-chart': navig === 'Apple Computer, Inc.' }]"
        >
        <svg
          @pointerdown="ctrlCursorOfChart($event)"
          @pointerup="ctrlCursorOfChart($event)"
          @wheel="ctrlCursorOfChart($event)">
        </svg>

        <svg style="height: 0; width: 0;">
          <defs>
            <linearGradient id="Gradient-1" x1="2%" y1="1%" x2="3.5%" y2="4%">
              <stop offset="0.05" stop-color="#ff0000" />
              <stop offset="0.21" stop-color="#000" />
            </linearGradient>

            <linearGradient id="repeat" 
              href="#Gradient-1" 
              spreadMethod="repeat" 
            />
          </defs>
        </svg>
      </section>

      <transition name="fade">
        <Preloader :mode="1" v-if="isLoaded" />
      </transition>
    </section>
  </section>
</template>

<script>
import ganttSmall from '@/mixins/gantt_small'

export default {
  name: 'ChartProjects',
  props: { projects: Array },
  mixins: [ganttSmall],
  data: () => ({
    width: window.innerWidth,
    countDays: 30,
    heightRow: 80,
    widthAside: 300,
    barIdForChangeStatus: '',
    zoom: 1,
    scaleOptions: [
      { label: '1 Day',   zoom: 0.5 },
      { label: '7 Days',  zoom: 1 },
      { label: '14 Days', zoom: 2 },
      { label: '30 Days', zoom: 4 },
    ],
    isLoaded: false,

    popUp: {
      isShow: false,
      data: {}
    }
  }),
  computed: {
    navig: function() { return navigator.vendor },
    today: function() {
      return new Date(this.$moment().add('-1', 'days'))
    },
    svgWidth: function() {
      return this.width
    },
    mindate: function() {
      return this.$moment(this.today).add('-3', 'days')._d
    },
    maxdate: function() {
      return this.$moment(this.mindate).add(this.countDays, 'days');
    },
  },
  methods: {
    setHeightChart() {
      const wrapperChart = document.querySelector('.chart');
      const chart = document.querySelector('.gantt');
      const topOffset = wrapperChart.getBoundingClientRect().y;
      const screenHeight = document.body.clientHeight;
      const height = screenHeight - topOffset - 80;
      
      wrapperChart.style.maxHeight = `${height}px`;
      chart.style.maxHeight = `${height}px`;
    },
  },
  mounted() {
    this.drowChart();
    this.setHeightChart();
  }
}
</script>
