<template>
  <section class="menu" :class="{'menu_comment': isShowcoment || isShowchecklist}">
    <div class="menu_wrap" v-if="id">
      <button v-for="(btn, i) in btnList" :key="i"
        class="btn btn_outline"
        :disabled="checkIsDisabled(btn)"
        :class="{'btn_outline_active': btn.id === task.status}"
        @click.prevent="ctrlStatusIssue(btn.id); $emit('ctrl', btn.event)">
          <font-awesome-icon :icon="btn.icon" />
      </button>
    </div>

    <transition name="fade">
      <div class="comment" v-if="isShowcoment">
        <el-input
          type="textarea"
          autosize
          placeholder="Коментарий"
          v-model="comment">
        </el-input>

        <button class="btn" @click="sendComment">
          <font-awesome-icon icon="paper-plane" />
        </button>
      </div>
    </transition>

    <transition name="fade">
      <div class="checklist" v-if="isShowchecklist && task && task.checklist">
        <el-checkbox v-for="(item, i) in checklist" :key="i" 
          v-model="item.checked"
          @change="checkItemCheckList(item.id)">
            {{ item.text }}
        </el-checkbox>
      </div>
    </transition>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ChartContextMenu',
  props: { 
    isShow: Boolean,
    tasks: Array, 
    id: String,
  },
  data: () => ({
    comment: '',
    isShowcoment: false,
    isShowchecklist: false,
    event: '',
    checkboxTask: {},
  }),
  computed: {
    ...mapGetters(['getTransitions', 'getLogTimes', 'getUser']),
    task: function() {
      return this.tasks.find(t => t.id === this.id)
    },
    btnList: function() {
      return [
        { id: this.getIdPlay(), icon: 'play',  event: 'inProgress' },
        { id: 'pause', icon: 'pause', event: 'pause' },
        { id: 'testing', icon: 'stop',  event: 'testing' },
        { id: 'needInfo', icon: 'info',  event: 'needInfo' },
        { id: 'check', icon: 'check',  event: 'check' },
      ]
    },
    taskDurations: function() {
      if ( this.getLogTimes ) {
        let d = 0;
        this.getLogTimes.forEach(obj => {
          d = this.$moment.duration(d);
          d.add(obj.duration)
        });
        return d.toISOString();
      }
    },
    checklist: function() {
      return [...this.task.checklist];
    }
  },
  methods: {
    ...mapActions([
      'fetchTransitions', 
      'changeStatusTask', 
      'fetchLogTimes',
      'playPauseLogTimes',
      'clearStateLogTime',
      'updateDateIssue',
      'addComment',
      'checkItem'
    ]),
    checkIsDisabled(el) {
      if ( (el.event === 'pause' && 
        this.task.status !== 'inProgress' && 
        this.task.status !== 'pause') || 
        (this.task.author !== this.getUser.uid && 
        this.task.user_id !== this.getUser.uid) ) {
          return true;
      };
      if ( el.event === 'check' && (!this.task.checklist || !this.task.checklist.length) ) return true;
    },
    async play() {
      let duration = 0,
          start = this.$moment().toISOString();

      await this.playPauseLogTimes({
        issue_id: this.id,
        start: start,
        duration: duration
      })
    },
    async pause() {
      const count = this.getLogTimes.length;
      let start = this.$moment(),
          duration = this.$moment(start.diff(this.getLogTimes[count - 1].start));
          
      duration = this.$moment.duration(duration).toISOString();
      await this.playPauseLogTimes({
        issue_id: this.id,
        start: start,
        duration: duration
      })
    },
    async stop() {
      let count = this.getLogTimes.length,
          duration = 0,
          start = this.$moment().toISOString();

      if ( this.task.status === 'pause' ) {
        await this.playPauseLogTimes({
          issue_id: this.id,
          start: start,
          duration: duration
        })
      } else {
        duration = this.$moment.duration(this.$moment().diff(this.getLogTimes[count - 1].start)).toISOString();
        await this.playPauseLogTimes({
          issue_id: this.id,
          start: start,
          duration: duration
        })
      }
      await this.changeStatusTask({ task_id: this.id, status_id: this.event });
    },
    async ctrlStatusIssue(ev) {
      switch(ev) {
        case 'inProgress':
        case 'start_progress':
          this.play()
          await this.changeStatusTask({ task_id: this.id, status_id: ev });
          break;
        case 'pause':
          this.pause()
          await this.changeStatusTask({ task_id: this.id, status_id: ev });
          break;
        case 'testing':
        case 'needInfo':
          this.isShowcoment = true;
          this.isShowchecklist = false;
          this.event = ev;
          break;
        case 'check':
          this.isShowchecklist = true;
          this.isShowcoment = false;
          this.event = ev;
          break;
      }; 
    },
    async sendComment() {
      await this.addComment({ issue_id: this.id, text: this.comment });
      await this.stop();
      this.isShowcoment = false;
      this.comment = '';
      this.$emit('close');
      setTimeout(() => {
        this.$emit('loading');
      }, 250)
    },
    getIdPlay() {
      let id = '';
      if ( this.getTransitions && this.getTransitions.length ) {
        if ( this.getTransitions.find(obj => obj.id === 'start_progress') ) {
          id = this.getTransitions.find(obj => obj.id === 'start_progress').id
        }
      }
      if ( !id ) id = 'inProgress'; 
      return id;
    },
    async checkItemCheckList(id) {
      const data = {
        checked: this.checklist.find(ch => ch.id === id).checked,
      };
      await this.checkItem({ data: data, issue_id: this.task.id, item_id: id });
      this.openMessage();
    },
    openMessage() {
      this.$message({
        message: 'Поздравляю с выполненым чем-то',
        type: 'success',
        customClass: 'app-message',
      });
    }
  },
  watch: {
    async id() {
      await this.clearStateLogTime();
      await this.fetchTransitions(this.id);
      await this.fetchLogTimes(this.id);
    },
    async isShow() {
      if ( this.isShow ) {
        await this.clearStateLogTime();
        await this.fetchTransitions(this.id);
        await this.fetchLogTimes(this.id);
      } else {
        this.isShowcoment = false;
        this.isShowchecklist = false;
        this.comment = '';
        await this.clearStateLogTime();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.menu {
  position: fixed;
  top: 0;
  left: 0;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(0,0,0,1);
  transition: background-color 0.3s ease, border-color 0.3s ease;

  &_comment {
    background-color: rgba(0,0,0,1);
    border-color: #fff;
    border-width: 1px;
    border-style: solid;
  }

  &_wrap {
    display: flex;
    gap: 5px; 
    & * {
      margin: 0, 8px;
    }
  }
}
.btn {
  border-radius: 5px;
  padding: 2px 10px;
  cursor: pointer;
  border-width: 0;
  font-size: 8px;

  &_outline {
    background: none;
    box-sizing: border-box;
    border-color: #b7b7b7;
    color: #b7b7b7;
    border-width: 1px;
    display: grid;
    place-items: center;
    width: 30px;
    transition: all .3s ease;

    &:hover {
      color: #fff;
      border-color: #fff;
    }
    &:disabled {
      pointer-events: none;
      border-color: #686868;
      color: #686868;
    }

    &_active {
      background-color: #fff;
      color: #000;
      transition: all .3s ease;
      pointer-events: none;
    }
  }
}
.comment {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin-top: 16px;
  min-width: 450px;

  & .el-textarea textarea {
    background-color: #000;
    border: 1px solid #fff;
    color: #fff;
  }

  & .btn {
    border-radius: 4px;
    display: flex;
    padding: 5px;
    justify-content: center;
    align-items: center;
    height: 34px;
    border: 1px solid #fff;
    min-width: 34px;
    cursor: pointer;
    background: #fff;
    font-size: 16px;
    color: #fff;
    background: #000;
  }
}
.checklist {
  margin-top: 16px;
  min-width: 450px;
  display: grid;
  gap: 5px;
  
}
</style>

<style lang="scss">
.comment {
  & .el-textarea textarea {
    background-color: #000;
    border: 1px solid #fff;
    color: #fff;
  }
}
</style>
