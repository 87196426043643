<template>

    <div v-if="matrix && matrix.length" class="matrix" :style="mShape">
      <div class="wrap" v-for="(item, index) in matrix" :key="index" :style="mShape" >

        <div class="capture"  :style="{...diagramRotate(index), ...mShape}"> 
          <div class="text" :style="{'background-color':`${mColors[index]}`, ...diagramRotate(index, '-')}">
            {{item.type}}
          </div>
        </div>
        <div class="data" :style="{...diagramForm(item.percentage * width/2, mColors[index]), ...diagramRotate(index)}"> 
        </div>
      </div>
        <div 
        class="circle" v-for="(diam, _id) in mCircles" :key="`_${_id}`" :style="{width: `${diam}px`, height: `${diam}px`}"> </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: "Matrix",
  props: {
    matrix: {
      type: Array,
    }
  },
  data: () => ({
    width: 400,
    diamCount: 11,
    mColors: [
        '#ACACACDD',
        '#CDD1CCDD',
        '#E2C547DD',
        '#C2DDC8DD',
        '#99BFB3DD',
    ],
  }),  
  computed: {
    angle() {
      if (this.matrix && this.matrix.length) {
        return 360/this.matrix.length;
      }
        return 60; //deg
    },
    mShape() {
      let shape = `${this.width}px`;
      return {width: shape, height: shape}
    },
    mCircles() {
      let self = this;
      let range = n => [...Array(n).keys()]
      return range(self.diamCount).map(function(i) {
        return self.width / self.diamCount * i
      })
    },
  },
  methods: {
    diagramRotate(index, direction="") {
      let anglePosition = this.angle * index;
      return {
        'transform': `rotate(${direction}${anglePosition}deg)`,
      };
    },
    diagramForm (value, color) {
      let borderLength = value * Math.tan(this.angle / 2 * Math.PI / 180);
      return {
        'border-left': `${borderLength}px solid transparent`,
        'border-right': `${borderLength}px solid transparent`,
        'border-top': `${value}px solid ${color}`,
      };
    },
  },
}
</script>

<style lang="scss">
  .matrix {
    z-index: 300;
    top: 150px;
    right: 200px;
    background: radial-gradient(circle, rgb(163, 163, 164) 10%, transparent 70%);
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
  }

  .matrix>.wrap {
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .capture {
    position: absolute;
    left:0;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: 390;
    transition: 1s;
  }


  .capture>.text {
    transform: rotate(-90deg);
    height: max-content;
    width: max-content;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    color: black;
    padding: 5px;
    transition: 1s;
  }



  .matrix>.wrap>.data {
    z-index: 300;
    -webkit-transform-origin: bottom center;
    -moz-transform-origin: bottom center;
    -o-transform-origin: bottom center;
    transform-origin: bottom center;
    position: absolute;
    border-radius: 49%;
    bottom: 50%;
    transition: 1s;
  }

  .matrix>.circle {
    position: absolute;
    z-index: 200;
    border: 1px solid #CCC;
    border-radius: 50%;
    text-align: center;
    transition: 1s;
  }

</style>